import { PaymentMethods, PaymentBrand, PaymentCountries } from '@/data/payment-methods.js';
export const paymentMethodsHomeMixin = {
    data() {
        return {
            PaymentMethods,
            PaymentBrand,
            first_row: PaymentMethods.slice(0, 4),
            second_row: PaymentMethods.slice(4)
        };
    },
    methods: {
        // 跳转到对应的country section
        gotoSection(toggle, key) {
            if (toggle)
                toggle();
            const target = this.$refs[`country-${key}`][0];
            const options = {
                duration: 300,
                offset: 0,
                easing: 'linear'
            };
            this.$vuetify.goTo(target, options);
        },
        // 跳转到对应的payment brand
        gotoType(brand) {
            this.$router.push(`/payment-methods/${brand}`);
        },
        isSmallIcon(brand) {
            return ['LCC', 'Konbini', 'BankTransfer'].indexOf(brand) > -1;
        }
    }
}

export const paymentMethodsDetailMixin = {
    data() {
        return {
            PaymentCountries,
            logoMap: {
                pc: require("@/assets/payment_methods/pc.svg"),
                mobile: require("@/assets/payment_methods/mobile.svg"),
                other: require("@/assets/payment_methods/mini-app.svg"),
            },
        };
    },
    computed: {
        brand() {
            return this.$route.params.brand;
        },
        brandFull() {
            return this.brand ? PaymentBrand[this.brand] : {};
        },
        paymentScenarios() {
            return Object.keys(this.brandFull.paymentScenarios);
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t("payment_methods.title"),
                    to: "/payment-methods",
                },
                {
                    text: this.brandFull.name,
                    disabled: true,
                    to: "",
                },
            ];
        },
    },
}