<template>
  <div class="evo-news-home-wrapper">
    <!-- country -->
    <v-item-group mandatory class="evo-country-wrap">
      <v-row class="evo-country-row" justify="center" no-gutters v-for="(row, index) in [first_row, second_row]" :key="index">
        <v-col
          v-for="item in row"
          :key="item.key"
          cols="12"
          sm="3"
          md="3"
          class="d-flex justify-center"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card class="country-item" :class="{ active }" @click="gotoSection(toggle, item.key)">
              <v-avatar size="17" class="mr-2" v-if="item.logo">
                <img loading="lazy" v-if="item.logo" :src="item.logo" size="52" />
              </v-avatar>
              <v-avatar size="17" class="mr-2" v-if="item.iconfont">
                <v-icon>{{ item.iconfont }}</v-icon>
              </v-avatar>
              <span class="evo-text-15 evo-opensans-regular">
                {{ item.name }}
              </span>
              <div class="line" v-show="active"></div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <!-- country section-->
    <v-row :ref="`country-${item.key}`" class="evo-card-wrap" v-for="item in PaymentMethods" :key="item.key">
      <v-col cols="12" sm="12" md="6">
        <div class="d-flex flex-column">
          <div class="evo-quote-mark" data-aos="fade-up">
            <img loading="lazy" class="evo-img-auto" src="@/assets/quote-mark.png" />
          </div>
          <div class="d-flex align-center" data-aos="fade-up">
            <v-avatar class="mr-6" v-if="item.logo">
              <img loading="lazy" :src="item.logo" size="52" />
            </v-avatar>
            <v-avatar class="mr-6" v-if="item.iconfont">
              <v-icon size="48">{{ item.iconfont }}</v-icon>
            </v-avatar>
            <div class="country-name evo-text-34">{{ item.name }}</div>
          </div>
          <div class="country-desc evo-text-14 evo-opensans-regular" data-aos="fade-up" data-aos-delay="100">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row>
          <v-col v-for="brand in item.brands" :key="brand" cols="12" sm="12" md="6" data-aos="fade-up">
            <v-card class="brand-card" @click="gotoType(brand)">
              <div class="brand-image">
                <img
                  loading="lazy"
                  class="evo-img-auto"
                  :class="{ 'evo-img-icon': isSmallIcon(brand) }"
                  :src="PaymentBrand[brand].smallLogo"
                  :alt="brand"
                />
              </div>
              <div class="brand-name evo-text-19">
                {{ PaymentBrand[brand].name }}
              </div>
              <div class="brand-desc evo-text-14 evo-opensans-regular">
                {{ $t(PaymentBrand[brand].shortDesc) }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { paymentMethodsHomeMixin } from '@/mixins/payment-methods.js';
export default {
  name: 'EvoPaymentMethodsHomeSection',
  mixins: [paymentMethodsHomeMixin]
};
</script>
<style lang="scss" scoped>
.evo-news-home-wrapper {
  position: relative;
  margin-top: 29px;

  .evo-country-wrap {
    margin-bottom: 66px;
  }

  .evo-country-row {
    position: relative;
    margin: 24px -40px;
    .country-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 177px;
      height: 34px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      color: #aeb7c1;
      line-height: 20px;

      &.active {
        color: #3b43f2;
      }
      .line {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 181px;
        height: 3px;
        background: #3b43f2;
        border-radius: 2px;
      }
    }
  }

  .evo-card-wrap {
    position: relative;
    margin-left: -40px;
    margin-right: -40px;
    & + .evo-card-wrap {
      margin-top: 96px;
    }
    .evo-quote-mark {
      width: 37px;
      height: 33px;
      overflow: hidden;
      margin-bottom: 52px;
    }
    .country-name {
      line-height: 47px;
      color: $title_color;
    }
    .country-desc {
      padding: 32px 0 0 6px;
      width: 283px;
      color: $title_color;
      line-height: 27px;
    }
    .brand-card {
      padding: 40px 24px 20px;
      min-height: 244px;
      background: #ffffff;
      box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.08);
      border-radius: 15px;
      overflow: hidden;
    }
    .brand-image {
      margin-bottom: 20px;
      max-height: 42px;

      img {
        overflow: hidden;
      }
    }
    .brand-name {
      color: $title_color;
      line-height: 28px;
      margin-bottom: 10px;
      min-height: 56px;
    }
    .brand-desc {
      color: #535960;
      line-height: 22px;
      min-height: 66px;
    }
  }

  .evo-img-icon {
    width: 43px !important;
  }
}
</style>
