<template>
  <div class="evo-news-home-wrapper">
    <!-- country -->
    <v-tabs :show-arrows="false" color="#3b42ec" background-color="white" class="evo-country-wrapper" center-active>
      <v-tab class="country-item" v-for="(item, index) in PaymentMethods" :key="index">
        <div @click="gotoSection(null, item.key)">
          <v-avatar class="evo-country-img mr-2" size="38" v-if="item.logo">
            <img loading="lazy" :src="item.logo" size="52" />
          </v-avatar>
          <v-avatar size="38" class="mr-2" v-if="item.iconfont">
            <v-icon>{{ item.iconfont }}</v-icon>
          </v-avatar>
          <span class="evo-text-32 evo-opensans-regular">
            {{ item.name }}
          </span>
        </div>
      </v-tab>
    </v-tabs>

    <!-- country section-->
    <v-row :ref="`country-${item.key}`" class="evo-card-wrap evo-mobile" v-for="item in PaymentMethods" :key="item.key">
      <v-col cols="12">
        <div class="d-flex flex-column">
          <div class="evo-quote-mark">
            <img loading="lazy" class="evo-img-auto" src="@/assets/quote-mark.png" />
          </div>
          <div class="d-flex align-center">
            <img class="evo-item-img" loading="lazy" :src="item.logo" v-if="item.logo" />
            <v-icon class="evo-item-img" size="14.666667vw" v-if="item.iconfont">{{ item.iconfont }}</v-icon>
            <div class="country-name evo-text-72">{{ item.name }}</div>
          </div>
          <div class="country-desc evo-text-29 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col v-for="brand in item.brands" :key="brand" cols="6">
            <v-card class="brand-card" @click="gotoType(brand)">
              <div class="brand-image">
                <img loading="lazy" :class="{ 'evo-img-icon': isSmallIcon(brand) }" :src="PaymentBrand[brand].smallLogo" :alt="brand" />
              </div>
              <div class="brand-name evo-text-30">
                {{ PaymentBrand[brand].name }}
              </div>
              <div class="brand-desc evo-text-22 evo-opensans-regular">
                {{ $t(PaymentBrand[brand].shortDesc) }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { paymentMethodsHomeMixin } from '@/mixins/payment-methods.js';
export default {
  name: 'EvoMPaymentMethodsHomeSection',
  mixins: [paymentMethodsHomeMixin]
};
</script>
<style lang="scss" scoped>
.evo-news-home-wrapper {
  position: relative;

  ::v-deep(.v-slide-group__prev) {
    display: none !important;
  }

  ::v-deep(.v-slide-group__next) {
    display: none !important;
  }

  .evo-country-img {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  }

  .evo-card-wrap {
    position: relative;
    margin: 0 auto;
    margin-top: 96px;

    & + .evo-card-wrap {
      margin-bottom: 96px;
    }

    .evo-item-img {
      width: 110px;
      height: auto;
      margin-right: 24px;
    }

    .evo-quote-mark {
      width: 80px;
      height: auto;
      overflow: hidden;
      margin-bottom: 52px;
    }
    .country-name {
      color: $title_color;
    }
    .country-desc {
      padding: 32px 0 0 6px;
      color: $desc_color;
    }
    .brand-card {
      padding: 48px;
      min-height: 400px;
      background: #ffffff;
      box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.08);
      border-radius: 15px;
      overflow: hidden;
    }
    .brand-image {
      margin-bottom: 20px;

      & img {
        width: 200px;
        height: auto;
      }
    }
    .brand-name {
      color: $title_color;
      margin-bottom: 24px;
    }
    .brand-desc {
      color: #535960;
    }
  }

  .evo-img-icon {
    width: 56px !important;
  }
}
</style>
