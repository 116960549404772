<template>
  <div class="evo-news-home-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMPaymentMethodsHomeSection />
    </template>
    <template v-else>
      <EvoPaymentMethodsHomeSection />
    </template>
  </div>
</template>
<script>
import EvoPaymentMethodsHomeSection from '@/components/payment_methods_sections/Home.vue';
import EvoMPaymentMethodsHomeSection from '@/mobile/components/payment_methods_sections/MHome.vue';
export default {
  name: 'EvoPaymentMethodsHomeView',
  components: {
    EvoPaymentMethodsHomeSection,
    EvoMPaymentMethodsHomeSection
  }
};
</script>
<style lang="scss" scoped>
.evo-news-home-wrapper {
  position: relative;
}
</style>
